require
  #urlArgs: 'b=#{(new Date()).getTime()}'
  paths:
    'config-settings':'/api/config/settings'
    'config-html':'/api/config/html'
    acnt: 'account'
    enrl: 'enrollment'
    error: 'utils/error'
    DATA: 'data'
    helpers: 'utils/helpers'
    vend: 'vendor/js'
    jquery: 'vendor/js/jquery/jquery'
    _: 'vendor/js/lodash/lodash'
    angular: 'vendor/js/angular/angular'
    'ui-router': 'vendor/js/angular-ui-router/angular-ui-router'
    ngMockE2E: 'vendor/js/angular-mocks/angular-mocks'
    ngStorage: 'vendor/js/ngstorage/ngStorage'
    ngSanitize: 'vendor/js/angular-sanitize/angular-sanitize'
    ngBootstrap: 'vendor/js/angular-bootstrap/ui-bootstrap-tpls'
    moment: 'vendor/js/moment/moment'
    'angular-moment': 'vendor/js/angular-moment/angular-moment'
  shim:
    angular:
      exports: 'angular'
    'ui-router':
      deps: ['angular']
    ngStorage:
      deps: ['angular']
    ngMockE2E:
      deps: ['angular']
    ngSanitize:
      deps: ['angular']
    ngBootstrap:
      deps: ['angular']
  , [
    'angular'
    'app'
  ] , (angular) ->

    angular.element(document).ready ->
      angular.bootstrap document, ['app']
